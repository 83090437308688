import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
import ArticleListSlice from '@slices/article-list'
// import ArticleListSlice from '@slices/article-list'

type Props = {
  data: any
  pageContext: any
}

const NewsIndex: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const slices = main?.data?.body

  const posts = props?.data?.posts
  // header = { ...header, socialLinks: footer?.data?.social_links }

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      <Slice allSlices={slices} />
      <ArticleListSlice
        showDate={true}
        posts={posts.edges.map(post => post)}
        loadMoreLabel={'See more News'}
      ></ArticleListSlice>
    </Layout>
  )
}

export default NewsIndex

export const pageQuery = graphql`
  query NewsIndexPage($locale: String) {
    meta: prismicNewsArchive(lang: { eq: $locale }) {
      url
      lang
      type
      alternate_languages {
        uid
        lang
        document {
          ... on PrismicNewsArchive {
            id
            url
          }
        }
      }
    }

    seo: prismicNewsArchive(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    main: prismicNewsArchive(lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicNewsArchiveDataBodyFullscreenImage {
            ...fullscreenImageNewsArchive
          }
          ... on PrismicNewsArchiveDataBodyFullscreenVideo {
            ...fullscreenVideoNewsArchive
          }
          ... on PrismicNewsArchiveDataBodyTitle {
            ...titleNewsArchive
          }
        }
      }
    }

    posts: allPrismicNewsPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
            }
            post_title {
              text
            }
            superscription {
              richText
            }
          }
        }
      }
    }

    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
